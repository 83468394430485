<template>
  <el-dialog title="转移余额" :visible.sync="transferVisible" width="880px" @close="hanldeClose"
    :close-on-click-modal="false">
    <el-form :model="formData" :rules="rules" ref="formDataRef" label-width="110px">
      <el-row type="flex">
        <el-col :span="8">
          <el-form-item label="转出项目" prop="balance_id">
            <el-select v-model="formData.balance_id" @change="hanldeBalanceChange" placeholder="请选择余额项">
              <el-option v-for="(item, index) of merchants_balance" :key="index" :value="item.balance_id"
                :label="item.fee_name"></el-option></el-select></el-form-item></el-col>
        <el-col :span="6" :offset="1">
          <el-form-item label="当前余额">
            <div>{{ selectData.balance || 0 }}</div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="转入目标" prop="fee_id">
            <el-select v-model="formData.fee_id" @change="hanldeTargetChange" placeholder="请选择余额项">
              <el-option v-for="(item, index) of targetList" :key="index" :value="item.fee_id"
                :label="item.fee_name"></el-option>
            </el-select> </el-form-item></el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="转移金额" prop="balance_money">
            <el-input-number placeholder="请输入现价格" :controls="false" :min="0" :step="0.01" v-model="formData.balance_money"
              class="yc-input-number" /></el-form-item></el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="上传凭证">
            <el-upload action :on-change="handlePreview" :show-file-list="false" :auto-upload="false">
              <div class="imgBox" v-if="formData.images.length > 0">
                <div class="imgList" v-for="(item, index) in formData.images" :key="index">
                  <i class="icon el-icon-circle-close" @click.stop="del(index)"></i>
                  <img :src="imgUrl + item" alt="" />
                </div>
              </div>
              <el-button icon="el-icon-upload2" type="primary">上传</el-button>
            </el-upload>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="备注" prop="remarks">
            <el-input v-model="formData.remarks" class="yc-input-number" type="text" placeholder="请输入内容" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <MyButton @click="close" right>取 消</MyButton>
      <MyButton type="primary" @click="transferConfirm">确 定</MyButton>
    </div>
  </el-dialog>
</template>
<script>
export default {
  components: {},
  props: {
    //merchants_balance 商户余额信息
    merchants_balance: {
      type: Array,
      default: () => [],
    },
    merchants_id: {
      type: Number | String,
      default: 0,
    },
  },
  data () {
    const checkMoney = (relues, value, callback) => {
      if (value > this.selectData.balance) {
        callback(new Error("输入金额大于当前余额"));
        return;
      }
      if (!value) {
        callback(new Error("输入金额"));
        return;
      }
      callback();
    };
    const checkFeeId = (rule, value, callback) => {
      if (this.selectData.fee_id == this.formData.fee_id) {
        callback(new Error("请选择不同类型余额"));
        return;
      }
      if (!value) {
        callback(new Error("请选择转入目标"));
        return;
      }
      callback();
    };
    return {
      formData: {
        balance_id: "",
        fee_id: "",
        balance_money: '',
        remarks: '',
        images: []
      },
      imgUrl: process.env.VUE_APP_IMG_URL,
      transferVisible: false,
      selectData: {
        balance: 0,
      },
      targetItem: {},
      targetList: [],
      rules: {
        balance_id: [
          {
            required: true,
            message: "请选择转移项",
            trigger: "change",
          },
          { validator: checkFeeId },
        ],
        balance_money: [
          { validator: checkMoney, required: true, trigger: "change" },
        ],
        fee_id: [{ validator: checkFeeId, required: true, trigger: "change" }],
      },
    };
  },
  mounted () {
    this.getTransferTarget();
  },
  methods: {
    del (index) {
      this.formData.images.splice(index, 1);
    },
    handlePreview (file) {
      if (this.formData.images.length >= 3) {
        this.$common.notifyError('最多上传三张凭证');
        return;
      }
      if (file && file.raw) {
        let formData = new FormData();
        formData.append("file", file.raw);
        this.$request.HttpPost("/system/upload/upImg", formData).then((res) => {
          this.formData.images.push(res.data.fileInfo.hostUrl);
        });
      }
    },
    checkMoney (rule, value, callback) {
      return callback(new Error("年龄不能为空"));
    },
    /**
     *
     * @param {*} e 选择商户余额项
     */
    hanldeBalanceChange (e) {
      this.$refs.formDataRef?.clearValidate();
      if (!e) return;
      this.selectData = this.merchants_balance.find(
        (item) => item.balance_id == e
      );
    },
    /**
     *
     * @param {*} e 选择转入余额目标
     */
    hanldeTargetChange (e) {
      this.$refs.formDataRef?.clearValidate();
      if (!e) return;
      this.targetItem = this.targetList.find((item) => item.fee_id == e);
    },
    getTransferTarget () {
      Promise.all([
        this.$request.HttpGet("/contract_fee_setting/listAll", {
          status: 1,
        }),
        // this.$request.HttpGet("/setting/dailySetting"),
      ]).then((res) => {
        console.log(res, "res");
        const tempArry = [];
        res.forEach((item, index) => {
          if (index == 0) {
            item.data.forEach((itemz) => {
              tempArry.push({
                fee_name: itemz.contract_fee_name,
                fee_id: itemz.id,
                bill_key: itemz.repeated_type == 20 ? 9 : 10,
              });
            });
          } else {
            // item.data.values_struct.forEach((itemz) => {
            //   tempArry.push({
            //     fee_name: itemz.type_name,
            //     fee_id: itemz.children.property_setting_id,
            //     bill_key: 20,
            //   });
            // });
          }
        });
        this.targetList = tempArry;
      });
    },
    transferConfirm () {
      this.$refs.formDataRef.validate((valid) => {
        if (valid) {
          this.$emit("transferConfirm", {
            ...this.formData,
            ...this.targetItem,
            merchants_id: this.merchants_id,
          });
        }
      });
    },
    close () {
      this.transferVisible = false;
      this.resetFields();
    },
    open () {
      this.transferVisible = true;
    },
    resetFields () {
      this.$refs.formDataRef.resetFields();
    },
    hanldeClose () {
      this.formData = {
        balance_id: "",
        fee_id: "",
        balance_money: '',
        remarks: '',
        images: []
      }
      this.selectData = {};
    },
  },
};
</script>
<style lang="scss" scoped>
.yc-input-number::v-deep {
  width: 100%;

  .el-input__inner {
    text-align: start;
  }
}

.imgBox {
  display: flex;

  .imgList {
    position: relative;
    margin-right: 15px;
    margin-bottom: 15px;
    width: 110px;
    height: 110px;

    i {
      position: absolute;
      top: 0;
      right: 0;
    }

    img {
      width: 110px;
      height: 110px;
    }
  }
}
</style>
