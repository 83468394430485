<template>
  <div>
    <Navlid :list="navlist"></Navlid>
    <div class="public-box">
      <div class="top-box">
        <div class="lefts-top pub">
          <div class="title">商户信息</div>
          <div class="list">
            <div class="item">
              <span>商户姓名:</span>
              <span>{{ detail && detail.merchants.contact_name }}</span>
            </div>
            <div class="item position">
              <span>余额</span>
              <el-tooltip effect="dark" content="点击查看余额详情" placement="top-start">
                <div class="tips" @click="showDetail">?</div>
              </el-tooltip>
            </div>
            <div class="item">
              <span>欠费金额</span>
            </div>
          </div>
          <div class="list">
            <div class="item">
              <span>商户号:</span>
              <span>{{ detail && detail.merchants.merchants_number }}</span>
            </div>
            <div class="item">
              <span class="ye">
                <span>￥</span>
                {{ balanceTotal }}
              </span>
            </div>
            <div class="item">
              <span class="qf">
                <span>￥</span>
                {{ detail && detail.merchants.owe_price }}
              </span>
            </div>
          </div>
          <div class="list">
            <div class="item">
              <span>联系电话:</span>
              <span>{{ detail && detail.merchants.merchants_contact }}</span>
            </div>
            <div class="item" style="cursor: pointer" @click="transferMoney">
              <span>转移</span>
              <span>
                <img src="../../../assets/img/right.png" alt />
              </span>
            </div>
            <div class="item" style="cursor: pointer" @click="backMoney">
              <span>退款</span>
              <span>
                <img src="../../../assets/img/right.png" alt />
              </span>
            </div>
          </div>
        </div>
        <!-- left-bottom -->
        <div class="lefts-bottom pub">
          <div class="title">账户余额</div>
          <el-table :data="merchants_balance" :header-cell-style="{
            'text-align': 'center',
            background: 'rgb(245, 245, 245)',
          }" :cell-style="{ 'text-align': 'center', height: '32px' }" height="80%" :row-class-name="tableRowClassName">
            <el-table-column label="费用类型" prop="fee_name"></el-table-column>
            <el-table-column label="余额" prop="balance"> </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="{ row }">
                <div class="slot-wrapper">
                  <div class="mg-right-middle">
                    <pop tips="转移" @myclick="transferMoney(row)" v-if="row.balance > 0">
                      <img class="icon" src="../../../assets/img/icon/transfer.png" alt />
                    </pop>
                  </div>
                  <div>
                    <pop tips="退款" @myclick="backMoney(row)" v-if="row.balance > 0">
                      <img class="icon" src="../../../assets/img/icon/tuikuan.png" alt />
                    </pop>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="warp-bottom pub">
        <div class="title">
          <span>缴费信息</span>
          <div>
            <!--            <MyButton-->
            <!--              title="设置缴费记录"-->
            <!--              :accessId="34340"-->
            <!--              @click="getsetRecord('setrecordvisible')"-->
            <!--            >-->
            <!--              <template slot="preImage">-->
            <!--                <img src="../../../unit/img/st.png" alt />-->
            <!--              </template>-->
            <!--            </MyButton>-->
            <MyButton title="费用减免记录" :accessId="34341" left right @click="getsetRecord('jmerecordvisible')">
              <template slot="preImage">
                <img src="../../../assets/img/his.png" alt />
              </template>
            </MyButton>
            <MyButton title="导出" :accessId="34390" @click="infoExport">
              <template slot="preImage">
                <img src="../../../unit/img/down.png" alt />
              </template>
            </MyButton>
          </div>
        </div>
        <el-table :data="dataList" :span-method="arraySpanMethod" height="70%"
          :header-cell-style="{ 'text-align': 'center', background: 'rgb(245, 245, 245)' }"
          :cell-style="{ 'text-align': 'center' }" :row-class-name="tableRowClassName">
          <el-table-column label="合同编号" prop="contact_name"></el-table-column>
          <el-table-column label="商铺号" prop="merchants_stall_number"></el-table-column>
          <el-table-column label="费用类型" prop="bill_name"></el-table-column>
          <el-table-column label="合同缴费时间" prop="confirm_time"></el-table-column>
          <el-table-column label="合同缴费周期" prop="property_time">
            <template slot-scope="scope">
              <span>{{ scope.row.property_time }} ~
                {{ scope.row.property_end_time }}</span>
            </template>
          </el-table-column>
          <el-table-column label="合同缴费金额" prop="key_total_price"></el-table-column>
          <el-table-column label="实缴金额" prop="real_pay_amount">
            <!-- <template slot-scope="scope">
              <span>{{ parseFloat(scope.row.key_update_price - scope.row.balance_price - scope.row.coupon_price -
                scope.row.give_discount) < 0 ? 0 : Number(parseFloat(scope.row.key_update_price - scope.row.balance_price
                  - scope.row.coupon_price - scope.row.give_discount).toFixed(2)) }}</span>
            </template> -->
          </el-table-column>
          <el-table-column label="余额抵扣" prop="balance_price"></el-table-column>
          <el-table-column label="减免金额" prop="coupon_price"></el-table-column>
          <el-table-column label="优惠金额" prop="give_discount"></el-table-column>
          <!-- <el-table-column label="抹零金额" prop="zero_price"></el-table-column> -->
          <el-table-column label="剩余待缴金额" prop="key_pay_price">
            <template slot-scope="scope">
              <span v-if="scope.row.bill_status == 0">{{
                scope.row.key_pay_price
              }}</span>
              <span v-else>/</span>
            </template>
          </el-table-column>
          <el-table-column label="费用情况">
            <template slot-scope="scope">
              <span v-if="scope.row.bill_status == 10">已结清</span>
              <span v-else>{{
                parseFloat(scope.row.key_update_price) > 0 ? "已缴费" : "未缴费"
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template #default="{ row }">
              <!--              <pop-->
              <!--                tips="设置缴费"-->
              <!--                :accessId="50954"-->
              <!--                @myclick="handlecharge(row, 10)"-->
              <!--              >-->
              <!--                <img class="icon" src="@/assets/img/icon/setJF.png" alt />-->
              <!--              </pop>-->
              <pop tips="费用减免" v-if="row.bill_status != 10 && row.bill_key == 10" :accessId="50955"
                @myclick="handlecharge(row, 20)" popLeft>
                <img class="icon" src="@/assets/img/icon/setJM.png" alt />
              </pop>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="warp-top">
        <div class="rights pub">
          <div class="title">
            <span>缴费记录</span>
            <MyButton title="导出" :accessId="34390" @click="recordExport">
              <template slot="preImage">
                <img src="../../../unit/img/down.png" alt />
              </template>
            </MyButton>
          </div>
          <el-table :data="expenseList" :header-cell-style="{
            'text-align': 'center',
            background: 'rgb(245, 245, 245)',
          }" :cell-style="{ 'text-align': 'center' }">
            <el-table-column label="凭证号" prop="voucher_number"></el-table-column>
            <el-table-column label="缴费类型" prop="bill_offline_name"></el-table-column>
            <el-table-column label="缴费金额" prop="key_total_price"></el-table-column>
            <el-table-column label="余额抵扣" prop="balance_deduction"></el-table-column>
            <el-table-column label="抹零金额" prop="zero_price"></el-table-column>
            <el-table-column label="减免金额" prop="coupon_price"></el-table-column>
            <el-table-column label="优惠金额" prop="discount_money"></el-table-column>
            <el-table-column label="实收金额" prop="key_pay_price"></el-table-column>
            <el-table-column label="缴费时间" prop="pay_time"></el-table-column>

            <el-table-column label="缴费方式">
              <template slot-scope="scope">
                <span>{{ getType(scope.row) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="对公转账凭证">
              <template #default="{ row }">
                <pop tips="查看" @myclick="checkdgong(row)">
                  <img src="../../../unit/img/xq.png" class="icon" alt />
                </pop>
              </template>
            </el-table-column>
            <el-table-column label="缴费凭证" width="80px">
              <template slot-scope="scope">
                <div class="slot-box">
                  <pop tips="查看" @myclick="examine(scope.row)">
                    <img src="../../../unit/img/xq.png" class="icon" alt />
                  </pop>
                </div>
              </template>
            </el-table-column>
          </el-table>

          <div class="apiPage">
            <el-pagination next-text="下一页" prev-text="上一页" layout="total, prev, pager, next" :total="total"
              :page-size="formInline.pageSize" :current-page="formInline.pageNum"
              @current-change="currentChange"></el-pagination>
          </div>
        </div>
      </div>
      <!-- 余额转移 -->
      <TransferMoney :merchants_id="id" :merchants_balance="merchants_balance" ref="transferRef"
        @transferConfirm="hanldeTransferSubmit"></TransferMoney>
      <el-dialog title="退款" :visible.sync="refundVisible" width="880px" @close="refundClose"
        :close-on-click-modal="false">
        <el-form :model="refundRuleform" :rules="refundRules" ref="refundRuleform" label-width="110px">
          <el-form-item label="退款合同" prop="contract_id">
            <el-select class="public-input" v-model="refundRuleform.contract_id" @change="changeContract" clearable
              placeholder="请选择合同">
              <el-option v-for="(item, index) in contractList" :key="index" :value="item.contract_id"
                :label="item.contract_no"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="退款项" prop="fee_id">
            <!-- <el-select class="public-input" v-model="refundRuleform.fee_id" @change="hanldeChangeFee" clearable
              placeholder="选择退款项">
              <el-option v-for="(item, index) in merchants_balance" :key="index" :value="item.fee_id"
                :label="item.fee_name"></el-option>
            </el-select> -->
            <el-select class="public-input" :disabled="!refundRuleform.contract_id" v-model="refundRuleform.fee_id"
              @change="hanldeChangeFee" clearable placeholder="请选择退款项">
              <el-option v-for="(item, index) of targetList" :key="index" :value="item.fee_id"
                :label="item.fee_name"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="退款账号" prop="refund_number">
            <el-input v-model="refundRuleform.refund_number" :disabled="!refundRuleform.contract_id" class="public-input"
              clearable placeholder="请输入退款账号" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="退款户名" prop="refund_name">
            <el-input v-model="refundRuleform.refund_name" :disabled="!refundRuleform.contract_id" class="public-input"
              clearable placeholder="请输入退款户名" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="退款金额" prop="refund_money">
            <el-input v-model="refundRuleform.refund_money" :disabled="!refundRuleform.contract_id" type="number"
              class="public-input" placeholder="请输入退款金额" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="退款凭证">
            <el-upload action :on-change="refundHandlePreview" :show-file-list="false" :auto-upload="false">
              <div class="imgBox" v-if="refundRuleform.refund_image.length > 0">
                <div class="imgList" v-for="(item, index) in refundRuleform.refund_image" :key="index">
                  <i class="icon el-icon-circle-close" @click.stop="refundDel(index)"></i>
                  <img :src="imgUrl + item" alt="" />
                </div>
              </div>
              <el-button icon="el-icon-upload2" type="primary">上传</el-button>
            </el-upload>
          </el-form-item>
          <el-form-item label="备注">
            <el-input v-model="refundRuleform.refund_remarks" :disabled="!refundRuleform.contract_id" class="public-input"
              placeholder="请输入备注" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <MyButton @click="refundVisible = false" right>取 消</MyButton>
          <MyButton type="primary" @click="refundConfirm">确 定</MyButton>
        </div>
      </el-dialog>
      <el-dialog title="余额详情" :visible.sync="isShowDetail" width="1230px" :close-on-click-modal="false">
        <el-form label-width="110px" label-position="top">
          <el-row :gutter="20">
            <el-col :span="12" v-for="(item, index) of merchants_balance" :key="index">
              <el-form-item :label="item.fee_name" prop="refund_number">
                <div class="balance-input">{{ item.balance }}</div>
              </el-form-item></el-col>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <MyButton @click="isShowDetail = false" right>确 认</MyButton>
        </div>
      </el-dialog>
      <el-dialog title="收费凭证" width="1230px" :visible.sync="vouchervasible" :close-on-click-modal="false">
        <object type="application/pdf" :data="bill_voucher" width="1200" height="500"></object>
        <template slot="footer">
          <MyButton left @click="vouchervasible = false">关闭</MyButton>
        </template>
      </el-dialog>
      <el-dialog title="设置缴费" :visible.sync="setchagevisible" @close="closeSetchage" :close-on-click-modal="false">
        <el-form label-width="120px" ref="setchageform" :model="setchage" :rules="setchageRules" label-position="right">
          <el-form-item :label="isType == 10 ? '缴费金额' : '减免金额'" prop="reduce_price">
            <el-input placeholder="请输入" v-model="setchage.reduce_price"></el-input>
          </el-form-item>
          <el-form-item label="原因">
            <el-input type="textarea" placeholder="请输入原因" v-model="setchage.coupon_reason"></el-input>
          </el-form-item>
          <el-form-item label="上传凭证">
            <el-upload action :on-change="handlePreview" :show-file-list="false" :auto-upload="false">
              <div class="imgBox" v-if="setchage.coupon_voucher.length > 0">
                <div class="imgList" v-for="(item, index) in setchage.coupon_voucher" :key="index">
                  <i class="icon el-icon-circle-close" @click.stop="del(index)"></i>
                  <img :src="imgUrl + item" alt="" />
                </div>
              </div>
              <el-button icon="el-icon-upload2" type="primary">上传</el-button>
            </el-upload>
          </el-form-item>
        </el-form>
        <template #footer>
          <MyButton @click="setchagevisible = false">取消</MyButton>
          <MyButton left type="primary" @click="setchageAffirm">确认</MyButton>
        </template>
      </el-dialog>
    </div>
    <el-dialog title="设置缴费记录" :visible.sync="setrecordvisible" width="80%" :close-on-click-modal="false">
      <setrecord ref="setrecordvisible"></setrecord>
      <template slot="footer">
        <!-- <MyButton
            type="primary"
            @click="$refs.setrecord && $refs.setrecord.exported()"
            >导出</MyButton
          > -->
        <MyButton style="margin-left: 15px" @click="setrecordvisible = false">关闭</MyButton>
      </template>
    </el-dialog>

    <!-- 减免 -->
    <el-dialog :close-on-click-modal="false" title="费用减免记录" width="80%" :visible.sync="jmerecordvisible">
      <jmerecord ref="jmerecordvisible" />
      <template #footer>
        <MyButton style="margin-left: 15px" @click="jmerecordvisible = false">关闭</MyButton>
      </template>
    </el-dialog>
    <el-dialog title="收费凭证" width="1230px" :visible.sync="vouchervasible" :close-on-click-modal="false">
      <!--    <Voucher :data="voucher"></Voucher>-->
      <!--      <object type="application/pdf" :data="bill_voucher" width="1200" height="500"></object>-->
      <GfG :detailDataRowObj="detailDataRowObj" :detailDataRow="detailDataRow"></GfG>
      <template slot="footer">
        <MyButton type="primary" @click="printDiv">打印</MyButton>
        <MyButton left @click="vouchervasible = false">关闭</MyButton>
      </template>
    </el-dialog>
    <el-dialog title="对公转账凭证" :visible.sync="dgvisible" :close-on-click-modal="false">
      <img class="dgimg" :src="gong_voucher" alt="" />
      <template #footer>
        <myButton @click="dgvisible = false">关闭</myButton>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import setrecord from "./daybillchild/setRecored.vue";
import jmerecord from "./jmrecord.vue";
import TransferMoney from "./cpns/transferMoney.vue";
import * as commjs from "../../../plugin/common";
import GfG from "../GFG";
export default {
  components: {
    setrecord,
    jmerecord,
    TransferMoney,
    GfG,
  },
  data () {
    return {
      navlist: [
        {
          title: "商户账单",
          url: "/charge/merchantbill/basebill",
        },
        {
          title: "基本账单",
          url: "/charge/merchantbill/basebill",
        },
        {
          title: "基本账单详情",
          url: "/charge/merchantbill/basebilldetail",
        },
      ],
      dgvisible: false,
      gong_voucher: "",
      bill_voucher: "",
      vouchervasible: false,
      dataList: [],
      expenseList: [],
      detail: null,
      id: "",
      total: 0,
      formInline: {
        pageNum: 1,
        pageSize: 10,
        bill_key: 40,
        bill_status: 10,
      },
      fixedList: [],
      refundVisible: false,
      setchage: {
        reduce_price: "",
        coupon_reason: "",
        coupon_voucher: [],
        coupon_type: "10",
        bill_id: "",
        apply_range: "",
      },
      setchageRules: {
        reduce_price: [
          {
            required: true,
            message: "请输入金额",
            trigger: "blur",
          },
          {
            validator: (rule, value, fn) => {
              if (value > this.setchage.key_pay_price) {
                return fn(
                  new Error(
                    `减免金额不能大于总金额${this.setchage.key_pay_price}`
                  )
                );
              }
              fn();
            },
            trigger: "blur",
          },
        ],
      },
      setchagevisible: false,
      refundRuleform: {
        contract_id: '',
        refund_number: "",
        refund_name: "",
        refund_money: "",
        refund_remarks: '',
        refund_image: []
      },
      refundRules: {
        contract_id: [{ required: true, message: "请选择合同", trigger: "change" }],
        fee_id: [{ required: true, message: "请选择退款项", trigger: "change" }],
        refund_number: [{ required: true, message: "请输入退款账号", trigger: "blur" }],
        refund_name: [{ required: true, message: "请输入退款户名", trigger: "blur" }],
        refund_money: [{ required: true, message: "请输入退款金额", trigger: "blur" }]
      },
      contractList: [],
      imgUrl: process.env.VUE_APP_IMG_URL,
      isType: "",
      setrecordvisible: false,
      jmerecordvisible: false,
      merchants_balance: [],
      balanceTotal: 0,
      isShowDetail: false,
      detailDataRow: [],
      targetList: [],
      detailDataRowObj: {
        merchants_stall_name: "",
        voucherName: "",
        electronicSeal: "",
        voucher_number: "",
        totalMoney: 0,
        preMoney: "",
        remark: "",
        userName: "",
        printTime: "",
        billTitemNo: "",
      },
    };
  },
  created () {
    this.id = this.$route.query.merchants_id;
    this.$parent.navshow = false;
    this.codeSet();
    this.getDetail();
    this.getRecord();
    this.getFixedList();
    let userInfo = window.localStorage.getItem("userInfo");
    if (userInfo) {
      this.detailDataRowObj.userName = JSON.parse(userInfo).worker.name || "";
    }
  },
  methods: {
    ...commjs,
    codeSet (type) {
      this.$request
        .HttpGet("/setting/edit", {
          key: "chargeNumberSetting",
        })
        .then((res) => {
          if (res.data) {
            this.detailDataRowObj.voucherName =
              res.data.values?.voucherName || "重庆海若实业有限公司";
            this.detailDataRowObj.electronicSeal =
              res.data.values?.electronicSeal;
          }
        });
    },
    getNumber (item) {
      let total = 0;
      total =
        item.coupon_price +
        item.balance_price +
        item.give_discount +
        item.zero_price;
      return total.toFixed(2) || 0.0;
    },
    printDiv () {
      this.detailDataRowObj.printTime = this.formatTimeHms(new Date(), true);
      this.$nextTick(() => {
        let divContents = document.getElementById("GFG").innerHTML;
        let a = window.open("", "");
        a.document.write("<html>");
        a.document.write("<body >");
        a.document.write(divContents);
        a.document.write("</body></html>");
        a.document.close();
        a.print();
      });
    },
    examine (row) {
      let isUrl = row.bill_voucher.search(/https:\/\/|http:\/\//);
      if (isUrl == -1) {
        this.bill_voucher = process.env.VUE_APP_LOGIN + "/" + row.bill_voucher;
      } else {
        this.bill_voucher = row.bill_voucher;
      }
      let rowData = [];
      if (row.payment_info) {
        rowData = JSON.parse(row.payment_info);
      }
      this.detailDataRowObj.merchants_stall_name = row.merchants_stall_name;
      this.detailDataRowObj.voucher_number = row.voucher_number;
      this.detailDataRowObj.remark = row.remark;
      this.detailDataRowObj.billTitemNo = row.bill_offline_no.slice(0, 6);
      switch (row.pay_type) {
        case 30:
          this.detailDataRowObj.pay_type = "现金";
          break;
        case 31:
          this.detailDataRowObj.pay_type = "扫码";
          break;
        case 32:
          this.detailDataRowObj.pay_type = "扫码枪";
          break;
        case 33:
          this.detailDataRowObj.pay_type = "刷卡";
          break;
        case 34:
          this.detailDataRowObj.pay_type = "对公转账";
          break;
      }

      let totalMoney = 0;
      rowData.forEach((item) => {
        totalMoney += item.payment_money * 1;
      });
      this.detailDataRowObj.totalMoney = totalMoney.toFixed(2);

      // much_balance_price
      if (row.advance_info) {
        let data = JSON.parse(row.advance_info);
        let preMoney = 0;
        data.forEach((item) => {
          preMoney += item.much_balance_price * 1;
        });
        this.detailDataRowObj.preMoney = preMoney.toFixed(2);
      }

      this.detailDataRow = rowData;
      this.vouchervasible = true;
    },
    showDetail () {
      this.isShowDetail = true;
    },
    transferMoney (row) {
      this.$refs.transferRef.open();
      if (!row.balance_id) return;
      this.$refs.transferRef.formData.balance_id = row.balance_id;
      this.$refs.transferRef.hanldeBalanceChange(row.balance_id);
    },
    hanldeTransferSubmit (value) {
      this.$request
        .HttpPost("/merchants_balance_change/add", value)
        .then((res) => {
          this.$common.notifySuccess("转移成功,待审核");
          this.getDetail();
          this.$refs.transferRef.close();
          this.$refs.transferRef.resetFields();
        });
    },
    del (index) {
      this.setchage.coupon_voucher.splice(index, 1);
    },
    refundDel (index) {
      this.refundRuleform.refund_image.splice(index, 1);
    },
    closeSetchage () {
      this.isType = "";
      this.setchage = {
        reduce_price: "",
        coupon_reason: "",
        coupon_voucher: [],
        coupon_type: "10",
        bill_id: "",
      };
      this.$refs.setchageform.clearValidate();
    },
    handlePreview (file) {
      if (file && file.raw) {
        let formData = new FormData();
        formData.append("file", file.raw);
        this.$request.HttpPost("/system/upload/upImg", formData).then((res) => {
          this.setchage.coupon_voucher.push(res.data.fileInfo.hostUrl);
        });
      }
    },
    refundHandlePreview (file) {
      if (this.refundRuleform.refund_image.length >= 3) {
        this.$common.notifyError('最多上传三张凭证');
        return;
      }
      if (file && file.raw) {
        let formData = new FormData();
        formData.append("file", file.raw);
        this.$request.HttpPost("/system/upload/upImg", formData).then((res) => {
          this.refundRuleform.refund_image.push(res.data.fileInfo.hostUrl);
        });
      }
    },
    backMoney (row) {
      this.$request.HttpGet('/contract/merchantList', {
        merchant_id: this.id
      }).then((res) => {
        this.contractList = res.data || [];
        if (row.fee_id) {
          this.refundRuleform.contract_id = row.contract_id;
          this.refundRuleform.fee_id = row.fee_id;
          this.hanldeChangeFee(row.fee_id);
        }
        this.refundRuleform.merchants_id = this.id;
        this.refundVisible = true;
      })

    },
    exports (row) {
      let isUrl = row.bill_voucher.search(/https:\/\/|http:\/\//);
      if (isUrl == -1) {
        this.bill_voucher = process.env.VUE_APP_LOGIN + "/" + row.bill_voucher;
      } else {
        this.bill_voucher = row.bill_voucher;
      }
      this.vouchervasible = true;
    },
    balanceRefund () {
      this.refundRuleform.merchants_id = this.id;
      this.refundRuleform.bill_id = 0;
      // this.refundRuleform.refund_money = this.balanceTotal;
      this.refundVisible = true;
    },
    refund (row) {
      console.log(row);
      this.refundRuleform.apply_desc = row.bill_name;
      this.refundRuleform.merchants_id = this.id;
      this.refundRuleform.user_name = this.detail.merchants.merchants_name;
      this.refundRuleform.bill_id = row.bill_id;
      this.refundRuleform.refund_money = row.key_update_price;
      this.refundVisible = true;
    },
    refundConfirm () {
      if (this.refundRuleform.refund_money <= 0) {
        this.$message({ type: "warning", message: "退款金额不小于等于0" });
        this.refundRuleform.refund_money = '';
        return;
      }
      this.$refs.refundRuleform.validate((valid) => {
        if (valid) {
          this.$request.HttpPost("/bill_refund/add", {
            ...this.refundRuleform,
            merchants_id: this.id,
            refund_image: JSON.stringify(this.refundRuleform.refund_image)
          }).then((res) => {
            this.$common.notifySuccess(res.msg);
            this.getFixedList();
            this.getDetail();
            this.refundVisible = false;
          });
        }
      });
    },
    refundClose () {
      this.refundRuleform = {
        refund_number: "",
        refund_name: "",
        refund_money: "",
        refund_remarks: '',
        refund_image: []
      };
    },
    getType (row) {
      let value = this.$chargeWay.find((item) => item.id == row.pay_type);
      return value ? value.value : "默认";
    },
    recordExport () {
      this.$request
        .HttpGet(
          "/bill_offline/export",
          {
            merchants_id: this.id,
            ...this.formInline,
          },
          {
            responseType: "blob",
          }
        )
        .then((res) => {
          let blob = new Blob([res]);
          let objectUrl = URL.createObjectURL(blob);
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = objectUrl;
          link.setAttribute("download", "缴费记录.xlsx");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link); // 下载完成移除元素
          window.URL.revokeObjectURL(objectUrl); // 释放掉blob对象
        });
    },
    infoExport () {
      this.$request
        .HttpGet(
          "/merchants/merchantsBillInfoExport",
          {
            merchants_id: this.id,
          },
          {
            responseType: "blob",
          }
        )
        .then((res) => {
          let blob = new Blob([res]);
          let objectUrl = URL.createObjectURL(blob);
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = objectUrl;
          link.setAttribute("download", "约定缴费信息.xlsx");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link); // 下载完成移除元素
          window.URL.revokeObjectURL(objectUrl); // 释放掉blob对象
        });
    },
    getFixedList () {
      this.$request
        .HttpGet("/merchants/merchantsBillFixedList", {
          merchants_id: this.id,
        })
        .then((res) => {
          this.fixedList = res.data || [];
        });
    },
    getRecord () {
      this.$request
        .HttpGet("/bill_offline/list", {
          merchants_id: this.id,
          ...this.formInline,
        })
        .then((res) => {
          this.expenseList = res.data.list || [];
          this.total = res.data.total;
        });
    },
    currentChange (page) {
      this.formInline.pageNum = page;
      this.getRecord();
    },
    arraySpanMethod ({ row, columnIndex }) {
      // if (columnIndex == 0) {
      //   if (row.rowspan > 0) {
      //     return {
      //       rowspan: row.rowspan,
      //       colspan: 1,
      //     };
      //   }
      //   return [0, 0];
      // }
    },
    getDetail () {
      this.$request.HttpGet("/merchants/merchantsBillInfo", {
        merchants_id: this.id,
      }).then((res) => {
        if (res.data.contract_bill) {
          let contract_id = "";
          let sum = 0;
          for (let i = 0; i < res.data.contract_bill.length;) {
            contract_id = res.data.contract_bill[i].contract_id;
            sum = 1;
            for (
              let j = parseInt(i) + 1;
              j < res.data.contract_bill.length;
              j++
            ) {
              if (contract_id == res.data.contract_bill[j].contract_id) {
                sum++;
              }
            }
            if (sum > 1) {
              res.data.contract_bill[i].rowspan = sum;
              i = parseInt(i) + sum;
            } else {
              res.data.contract_bill[i].rowspan = 1;
              i++;
            }
          }
          this.dataList = res.data.contract_bill;
          console.log("  this.dataList", this.dataList);

          // this.dataList.fill(res.data.contract_bill,5)
        }
        this.detail = res.data;
        this.merchants_balance = res.data?.merchants?.merchants_balance ?? [];
        let total = 0;
        this.merchants_balance.forEach((item) => {
          total += item.balance;
        });
        this.balanceTotal = Math.round(total * 100) / 100;
      });
    },
    tableRowClassName () {
      return "cellHeight";
    },
    handlecharge (row, type) {
      this.isType = type;
      if (type == 20) {
        this.setchage.merchants_ids = [row.merchants_id];
        this.setchage.name = this.detail.merchants.contact_name;
        this.setchage.key_pay_price = row.key_pay_price;
        this.setchage.apply_range = row.contract_fee_id;
        this.setchage.bill_id = row.bill_id;
      } else {
        this.setchage.bill_id = row.bill_id;
      }
      this.setchagevisible = true;
    },
    setchageAffirm () {
      this.$refs.setchageform.validate((valid) => {
        if (valid) {
          let url = "";
          if (this.isType == 10) {
            url = "/bill_coupon/add";
          } else {
            url = "/coupon/add";
          }
          this.$request
            .HttpPost(url, {
              ...this.setchage,
              coupon_voucher: JSON.stringify(this.setchage.coupon_voucher),
            })
            .then((res) => {
              this.$common.notifySuccess(res.msg);
              this.getFixedList();
              this.getDetail();
              this.setchagevisible = false;
            });
        }
      });
    },
    getsetRecord (type) {
      this[type] = true;
      this.$nextTick(() => {
        this.$refs[type].getList();
      });
    },
    checkdgong (row) {
      if (!row.gong_voucher)
        return this.$message({ type: "warning", message: "暂无凭证" });
      this.gong_voucher = this.getImg(row.gong_voucher);
      if (!this.gong_voucher || this.gong_voucher.length === 0)
        return this.$message({ type: "warning", message: "暂无凭证" });
      this.dgvisible = true;
    },
    changeContract (value) {
      if (value) {
        this.$request.HttpGet('/contract/feeSettingList', {
          contract_id: value
        }).then((res) => {
          let tempArry = [];
          if (res.data) {
            res.data.forEach((itemz) => {
              tempArry.push({
                fee_name: itemz.contract_fee_name,
                fee_id: itemz.contract_fee_id,
                bill_key: itemz.bill_key,
              });
            });
          }
          this.refundClose();
          this.targetList = tempArry;
          this.refundRuleform.contract_id = value;
        })
      }
    },
    hanldeChangeFee (value) {
      if (this.refundRuleform.contract_id) {
        const feeInfo = this.targetList.find((item) => item.fee_id == value);
        if (feeInfo) {
          this.refundRuleform.fee_name = feeInfo.fee_name;
          this.refundRuleform.fee_id = feeInfo.fee_id;
          this.refundRuleform.bill_key = feeInfo.bill_key;
        } else {
          this.refundRuleform.fee_name = '';
          this.refundRuleform.fee_id = '';
          this.refundRuleform.bill_key = '';
        }
      } else {
        this.refundRuleform.fee_id = '';
        this.$message({ type: "warning", message: "请先选择退款合同" });
      }
    },
  },
  beforeDestroy () {
    this.$parent.navshow = true;
  },
};
</script>
<style lang="scss" scoped>
.balance-detail {
  font-size: 12px;
  color: #999999;
  display: flex;
  flex-wrap: wrap;
  width: 300px;

  .item {
    height: 20px;
    display: flex;

    .label {
      margin-right: 20px;
    }
  }
}

.pub {
  padding: 20px;
  box-sizing: border-box;
  overflow: hidden;

  .title {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.top-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
  padding: 0;

  .lefts-top {
    flex: 1;
    height: 250px;
    border: 1px solid #dedede;
    box-sizing: border-box;

    .list {
      display: flex;
      align-items: center;
      margin-top: 15px;
      padding-left: 40px;
      box-sizing: border-box;

      .item {
        &:first-child {
          width: 250px;
        }

        &:nth-child(2) {
          width: 150px;
        }

        span {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          white-space: nowrap;

          &:nth-child(1) {
            color: #999999;
            width: 150px;
          }

          &:nth-child(2) {
            color: #000000;
          }

          img {
            width: 14px;
            height: 14px;
          }
        }

        .ye {
          font-size: 16px !important;

          font-weight: bold;
          color: #02ce80 !important;

          span {
            font-size: 12px;
          }
        }

        .qf {
          // width: 120px;
          white-space: nowrap;
          font-size: 16px !important;
          font-weight: bold;
          color: #f8c405 !important;

          span {
            font-size: 12px;
          }
        }
      }
    }
  }

  .lefts-bottom {
    flex: 1;
    height: 250px;
    border: 1px solid #dedede;
    margin-left: 20px;
    box-sizing: border-box;
  }
}

.warp-bottom {
  width: 100%;
  border: 1px solid #dedede;
  height: calc((100vh - 150px) / 2);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  z-index: 9999;
  // background-color: red;
}

.el-table .cellHeight {
  height: 32px;
}

/deep/ .el-table table {
  width: 100% !important;
  background-color: red;
}

.imgBox {
  display: flex;

  .imgList {
    position: relative;
    margin-right: 15px;
    margin-bottom: 15px;
    width: 110px;
    height: 110px;

    i {
      position: absolute;
      top: 0;
      right: 0;
    }

    img {
      width: 110px;
      height: 110px;
    }
  }
}

.dgimg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.position {
  position: relative;

  .tips {
    position: absolute;
    left: 25px;
    top: -5px;
    border: 1px solid #eaeaea;
    border-radius: 50%;
    width: 20px;
    text-align: center;
    transform: scale(0.8);
    cursor: pointer;
  }
}

.balance-input {
  border: 1px solid #eaeaea;
  padding-left: 9px;
  border-radius: 10px;
}

.slot-wrapper {
  display: flex;
  justify-content: center;
}

.mg-right-middle {
  margin-right: 20px;
}
</style>
