<template>
  <div>
    <div class="formline">
      <el-form label-position="right" label-width="120px" inline>
        <el-form-item label="提交时间">
          <el-date-picker type="daterange" @change="$timeChange($event, form, 'beginTime', 'endTime')"
            value-format="yyyy-MM-dd" v-model="date" placeholder=""></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-select placeholder="请选择提交人" clearable @change="getList(1)" v-model="form.workerId">
            <el-option v-for="(item, index) in workList" :key="index" :label="item.name"
              :value="item.user_id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="form.is_agree" clearable @change="getList" placeholder="审批状态">
            <el-option label="待审核" :value="10"></el-option>
            <el-option label="同意" :value="20"></el-option>
            <el-option label="拒绝" :value="30"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <MyButton @click="getList">搜索</MyButton>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="dataList" max-height="500px" v-loading="loading" :header-cell-style="{
      'text-align': 'center',
      background: 'rgb(245, 245, 245)',
    }" :cell-style="{ 'text-align': 'center' }">
      <el-table-column label="提交人" prop="worker_name"></el-table-column>
      <el-table-column label="提交时间" prop="create_at"></el-table-column>
      <el-table-column label="减免费用" prop="bill_name">
        <template>
          <span>基础费用</span>
        </template>
      </el-table-column>
      <el-table-column label="减免金额（元）" prop="reduce_price"></el-table-column>
      <el-table-column label="原因" prop="coupon_reason"></el-table-column>
      <el-table-column label="凭证" prop="pay_time">
        <template #default="{ row }">
          <pop tips="查看" @myclick="checkvoucher(row)">
            <img src="../../../unit/img/yl.png" class="icon" alt="" />
          </pop>
        </template>
      </el-table-column>
      <el-table-column label="审批状态">
        <template #default="{ row }">
          <span v-if="row.is_agree == 10">待审核</span>
          <span v-if="row.is_agree == 20">同意</span>
          <span v-if="row.is_agree == 30">拒绝</span>
        </template>
      </el-table-column>
      <el-table-column label="审批人" prop="agree_worker_name"></el-table-column>
      <el-table-column label="审批时间">
        <template #default="{ row }">
          <span v-if="row.is_agree == 10">/</span>
          <span v-else>{{ row.agree_at }}</span>
        </template>
      </el-table-column>
      <el-table-column label="备注" prop="agree_remark"></el-table-column>
    </el-table>
    <div class="apiPage">
      <el-pagination :total="total" :current-page="form.page" :page-size="form.limit" layout="total,prev,pager,next"
        next-text="下一页" prev-text="上一页" @current-change="getList"></el-pagination>
    </div>
    <el-dialog title="凭证" :visible.sync="vouchervisible" append-to-body :close-on-click-modal="false">
      <el-carousel height="700px" indicator-position="outside">
        <el-carousel-item v-for="(item, index) in voucher" :key="index">
          <!-- <img class="voucher-img" :src="$common.hashost(item)" alt="" /> -->
          <el-image :src="$common.hashost(item)" class="voucher-img" fit="contain"></el-image>
        </el-carousel-item>
      </el-carousel>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "jmerecord",
  data () {
    return {
      dataList: [{ id: 2 }],
      date: "",
      form: {
        page: 1,
        bill_key: 10,
        coupon_type: 10,
      },
      total: 0,
      loading: false,
      vouchervisible: false,
      voucher: [],
      workList: [],
    };
  },
  created () {
    this.form.bill_key = this.$route.query.bill_key;
    // this.form.contract_id = this.$route.query.contract_id;
    this.form.merchants_id = this.$route.query.merchants_id;
    this.getWorker();
  },
  filters: {
    payType (type) {
      let value = this.$chargeWay.find((item) => item.id == type);
      return value ? value.value : "默认";
    },
  },
  methods: {
    jump (row) {
      if (!row.bill_voucher) {
        this.$message("暂无凭证");
        return false;
      }
      let bill_voucher = "";
      let isUrl = row.bill_voucher.search(/https:\/\/|http:\/\//);
      if (isUrl == -1) {
        bill_voucher = process.env.VUE_APP_LOGIN + "/" + row.bill_voucher;
      } else {
        bill_voucher = row.bill_voucher;
      }
      window.open(bill_voucher);
    },
    getList (e = 1) {
      this.dataList = [];
      this.loading = true;
      this.form.pageNum = e;
      this.$request
        .HttpGet("/coupon/list", this.form)
        .then((res) => {
          this.loading = false;
          if (res.data) {
            this.total = res.data.total;
            this.dataList = res.data.list;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getWorker () {
      this.$request
        .HttpPost("/user/list", {
          sex: -1,
          pageNum: 1,
          pageSize: 99999,
        })
        .then((res) => {
          this.workList = res.data.list || [];
        });
    },
    exported () {
      this.$exported("/bill_coupon/export", this.form, "设置缴费记录导出.xlsx");
    },
    checkvoucher (row) {
      if (!row.coupon_voucher)
        return this.$message({ message: "暂无凭证", type: "warning" });
      try {
        this.voucher = JSON.parse(row.coupon_voucher);
      } catch {
        this.voucher = [row.coupon_voucher];
      }
      this.vouchervisible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.voucher-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
