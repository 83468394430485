<template>
  <div>
    <div id="GFG"
      style="position: relative; width: 100%; text-align: center; font-family: 'Microsoft YaHei'; color: black; padding: 0; margin: 0;">
      <div v-for="(item, j) in page" :key="item" :style="{ marginTop: Number(j) != 0 ? '300px' : '0' }">
        <div
          style="font-size: 14px; font-weight: bold; color: black; text-align: center; letter-spacing: 5px;position: relative;">
          {{ userInfo.market.marketName }}
          {{ detailDataRowObj.billTitemNo }}收款单据（{{ detailDataRowObj.pay_type }}）
          <span style="position:absolute; right:0; top: 0; letter-spacing: 1px; font-weight: 400;font-size: 12px;">
            {{ item }}/{{ page }}
          </span>
        </div>
        <div style="display: flex; align-items: center;justify-content: space-between; font-size: 12px;">
          <span style="color: black"></span>
          <span style="color: black">商户：{{ detailDataRowObj.merchants_stall_name }}</span>
          <span style="color: black">单据编号：{{ detailDataRowObj.voucher_number || "" }}</span>
        </div>
        <table border
          style="border-collapse: collapse; width: 100%; text-align: center; color: black; border-color: black; font-size: 10px;">
          <tr style="height: 30px; border-color: black; font-size: 10px;">
            <td style="width: 85px; color: black; border-color: black">项目名称</td>
            <td style="width: 80px; color: black; border-color: black">上次读数</td>
            <td style="width: 80px; color: black; border-color: black">本次读数</td>
            <td style="width: 85px; color: black; border-color: black">计费用量</td>
            <td style="width: 90px; color: black; border-color: black">房屋编号</td>
            <td style="width: 70px; color: black; border-color: black">面积</td>
            <td style="width: 50px; color: black; border-color: black">单价</td>
            <td style="width: 80px; color: black; border-color: black">应收款</td>
            <td style="width: 80px; color: black; border-color: black">抵扣款</td>
            <td style="width: 80px; color: black; border-color: black">实收款</td>
            <td style="width: 110px; color: black; border-color: black">开始日期</td>
            <td style="width: 110px; color: black; border-color: black">结束日期</td>
          </tr>
          <tr style="height: 30px;color: black; border-color: black; font-size: 10px;"
            v-for="(item, index) in detailDataRow.slice((item - 1) * 3, item * 3)" :key="index">
            <td>{{ item.contract_fee_name || "" }}</td>
            <td>{{ item.old_key_number || 0 }}</td>
            <td>{{ item.key_number || 0 }}</td>
            <td>{{ item.now_key_number || 0 }}</td>
            <td>{{ item.merchants_stall_number || "" }}</td>
            <td>{{ item.merchants_stall_size || "" }}</td>
            <td>{{ item.bill_key == 20 ? item.key_price : 0 }}</td>
            <td>{{ item.key_pay_price || "" }}</td>
            <td>{{ getNumber(item) || "" }}</td>
            <td>{{ item.payment_money || "" }}</td>
            <td>{{ $common.formatTimeHms(item.start_time, false) || "" }}</td>
            <td>{{ $common.formatTimeHms(item.end_time, false) || "" }}</td>
          </tr>
          <tr style="height: 30px; border-color: black;font-size: 10px;"
            v-if="!detailDataRow || detailDataRow.length === 0">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr style="height: 30px; color: black;border-color: black;font-size: 10px;" v-if="item == page">
            <td>实收款</td>
            <td colspan="2">{{ $common.outputmoney(detailDataRowObj.totalMoney) }}</td>
            <td>违约金</td>
            <td>0</td>
            <td>预付款</td>
            <td colspan="2">{{ $common.outputmoney(Number(detailDataRowObj.preMoney)) }}</td>
            <td colspan="4" style="text-align: left; padding-left: 10px;">
              合计：￥{{ $common.outputmoney(parseFloat(detailDataRowObj.totalMoney) + parseFloat(detailDataRowObj.preMoney))
              }}
              （{{ $common.intToChinese(Number(parseFloat(detailDataRowObj.totalMoney) +
                parseFloat(detailDataRowObj.preMoney))) }}）
            </td>
          </tr>
          <tr style="height: 30px; color: black; border-color: black;font-size: 10px;" v-if="item == page">
            <td>备注</td>
            <td colspan="11" style="text-align: left; padding-left: 10px;">{{ detailDataRowObj.remark }}</td>
          </tr>
        </table>
        <div style="width: 60%;display: flex; align-items: center; justify-content: space-between;font-size: 12px;">
          <span style="color: black">开票人：{{ detailDataRowObj.userName }}</span>
          <span style="color: black">打印时间：{{ detailDataRowObj.printTime }}</span>
        </div>
        <div style="width: 100%; display: flex; align-items: center; font-size: 12px;">
          <span style="color: black">欢迎关注微信公众号：海领农产品批发市场。</span>
        </div>
        <div style="width: 100%; display: flex; justify-content: flex-end; height: 0"
          v-if="detailDataRowObj.electronicSeal">
          <img :src="detailDataRowObj.electronicSeal"
            style="position: relative; width: 100px;height: 100px;bottom: 80px;right: 20px;border-radius: 50%;" />
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "GFG",
  props: {
    detailDataRowObj: {
      type: Object,
      default: () => {
        return {
          merchants_stall_name: "",
          voucherName: "",
          electronicSeal: "",
          voucher_number: "",
          totalMoney: 0,
          preMoney: "",
          remark: "",
          userName: "",
          printTime: "",
          billTitemNo: "",
        };
      },
    },
    detailDataRow: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    detailDataRow (val) {
      if (val.length > 3) {
        this.page = Math.ceil(val.length / 3);
      }
    }
  },
  data () {
    return {
      userInfo: window.localStorage.getItem('userInfo') ? JSON.parse(window.localStorage.getItem("userInfo")) : {},
      page: 1
    }
  },
  created () {
    if (this.userInfo) {
      this.detailDataRowObj.userName = this.userInfo.worker.name || "";
    }
    if (this.detailDataRow.length > 3) {
      this.page = Math.ceil(this.detailDataRow.length / 3);
    }
  },
  methods: {
    getNumber (item) {
      let total = 0;
      total =
        item.coupon_price +
        item.balance_price +
        item.give_discount +
        item.zero_price;
      return total.toFixed(2) || 0.0;
    },
  },
};
</script>

<style scoped></style>
